export default {
    highlight(keyword, content, classnames) {
        if(!keyword) {
            return content;
        }
        try {
          return content.replace(new RegExp(escapeRegExp(keyword), "gi"), match => {
              return '<span class="' + classnames + '">' + match + '</span>';
          });
        } catch {
          return content;
        }

        function escapeRegExp(content) {
          // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#Escaping
          return content.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
        }
    },
}