//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import VueQRCodeComponent from 'vue-qr-generator'

export default {
  name: 'SnsDialog',
  props: ['show', 'snsContent'],
  data: function () {
    return {
      // content: this.snsContent,
      // fullquote: `“${ (this.snsContent.name || '').trim() }” –– ${ (this.snsContent.title || '').trim() }`
    }
  },  
  computed: {
    isShowing: function() {
      return this.show
    },
    fullquote: function() {
      return `"${ (this.snsContent.name || '').trim() }" -- ${ (this.snsContent.title || '').trim() }`;
    }
  },
  methods: {
    onCopy: function(e) {
      console.debug(e)
      this.$emit('copied', e)
    }
  },
  components: {
    // VueQRCodeComponent
  }
}
